<template>
  <default-layout no-auth>
    <form>
      <div class="contents-block has-blue-border">
        <div class="contents-block__body is-narrow">
          <div class="c-block">
            <h1 class="c-heading__lv1"><span class="c-heading__title">認証コードの入力</span></h1>
          </div>
          <div class="c-block u-tac u-mb20">
            <p class="c-text">メールを送信しました。メールに記載されている6桁の数字を入力してください。</p>
          </div>
          <alert></alert>
          <div class="c-block">
            <div class="c-input-code" :class="{ wrong: codeError }">
              <input class="c-input__text" type="text" v-model="code" placeholder="000000" maxlength="6">
            </div>
          </div>
          <div class="c-block u-tac">
            <p class="c-text">
              しばらく待ってもメールが届かない場合は <a class="c-link__text" @click="$move('email')">前の画面に戻って</a><br>
              メールアドレスを確認し、再度認証コードを送信してください。
            </p>
          </div>
        </div>
        <div class="contents-block__footer">
          <button class="c-btn is-short u-ma" @click="checkCode" type="button" :disabled="!filled"><span>次へ</span></button>
        </div>
      </div>
    </form>
  </default-layout>
</template>

<script>
import {Service} from './_resource';

export default {
  data () {
    return {
      code: "",
      codeError: false,
    }
  },
  computed: {
    filled() {
      return this.code.length == 6;
    }
  },
  created () {
    Service.getSignUpInfo()
        .then(data => {
          if (data == null) {
            this.$move("start");
            return;
          }
          if (data.email == null) {
            this.$move("email");
            return;
          }
          if (data.confirmed) {
            this.$move("register");
            return;
          }
        })
        .catch(() => {
          this.$move("start");
        })
  },
  methods: {
    shake () {
      this.code = "";
      this.codeError = true;
      setTimeout(() => { this.codeError = false; }, 360);
    },
    checkCode () {
      if (this.code.trim().length != 6) {
        this.shake();
        return;
      }
      Service.confirmCode(this.code)
          .then(() => {
            this.$move("register");
          })
          .catch(() => this.shake());
    }
  }
};
</script>

<style scoped lang="scss">
.wrong {
  animation: shake 0.12s linear;
  animation-iteration-count: 3;
}
@keyframes shake {
  0% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-3%);
  }
  80% {
    transform: translateX(3%);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
