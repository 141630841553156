<template>
  <default-layout no-auth>
    <form autocomplete="off">
      <div class="contents-block has-blue-border">
        <div class="contents-block__body is-narrow">
          <div class="c-block__lg">
            <div class="c-block">
              <h1 class="c-heading__lv1"><span class="c-heading__title">新規会員登録</span></h1>
            </div>
            <alert></alert>
            <div class="c-block u-tac">
              <p v-if="userAccountId" class="c-text u-red">既にご登録いただいているアカウントに会員情報を追加します。</p>
              <p class="c-text">全ての項目を入力してください。</p>
              <p class="c-form-ex__text">（ご入力いただいた内容は後で変更することも可能です）</p>
            </div>
            <div class="c-block">
              <dl class="c-form-block is-narrow">
                <dt>
                  <label class="c-form-label" for="">会員氏名（ローマ字）</label>
                </dt>
                <dd>
                  <div class="c-input-en-name">
                    <div>
                      <label>名</label>
                      <input class="c-input__text" type="text" placeholder="TARO" autocomplete="off" name="given_name_en" v-model="form.givenNameEn">
                      <span></span><field-error v-if="errors" :errors="errors" name="givenNameEn"/>
                    </div>
                    <div>
                      <label>姓</label>
                      <input class="c-input__text" type="text" placeholder="YAMADA" autocomplete="off" name="family_name_en" v-model="form.familyNameEn">
                      <field-error v-if="errors" :errors="errors" name="familyNameEn"/>
                    </div>
                  </div>
                </dd>
                <dt>
                  <label class="c-form-label" for="">都道府県</label>
                </dt>
                <dd>
                  <div class="c-input-select">
                    <select v-if="prefectures" v-model="form.prefecture">
                      <option value="" hidden>都道府県選択</option>
                      <option v-for="p in prefectures" :value="p.id" :key="p.id">{{p.name}}</option>
                    </select>
                  </div>
                  <field-error :errors="errors" name="prefecture"/>
                </dd>
                <template v-if="!userAccountId">
                  <dt>
                    <label class="c-form-label" for="">パスワード</label>
                  </dt>
                  <dd>
                    <input class="c-input__text" type="password" autocomplete="new-password" name="password" placeholder="" v-model="form.password">
                    <field-error :errors="errors" name="password"/>
                    <div class="c-form-ex">
                      <p class="c-form-ex__text">英字・数字をそれぞれ１文字以上含め、8文字以上入力してください。</p>
                    </div>
                  </dd>
                  <dt>
                    <label class="c-form-label" for="">パスワード（確認用）</label>
                  </dt>
                  <dd>
                    <input class="c-input__text" type="password" autocomplete="new-password" name="confirmationPassword" placeholder="" v-model="form.confirmationPassword">
                    <field-error :errors="errors" name="confirmationPassword"/>
                    <div class="c-form-ex">
                      <p class="c-form-ex__text">上のパスワードと同じ内容を入力してください。</p>
                    </div>
                  </dd>
                </template>
              </dl>
            </div>
          </div>
        </div>
        <div class="contents-block__footer">
          <button v-if="userAccountId" class="c-btn is-short u-ma" @click="addMember" type="button"><span>登録</span></button>
          <button v-else class="c-btn is-short u-ma" @click="register" type="button"><span>登録</span></button>
        </div>
      </div>
    </form>
  </default-layout>
</template>

<script>
import {Service} from "./_resource";
import {AuthService} from "@/lib/auth/AuthService";
import FieldError from "@/lib/layout/FieldError";

export default {
  components: {FieldError},
  data () {
    return {
      form: {
        familyNameEn: "",
        givenNameEn: "",
        prefecture: "",
        password: "",
        confirmationPassword: ""
      },
      email: "",
      prefectures: [],
      memberId: null,
      userAccountId: null,
      errors: {}
    }
  },
  created () {
    Service.getSignUpInfo()
        .then(data => {
          if (data == null) {
            this.$move("start");
            return;
          }
          if (data.email == null) {
            this.$move("email");
            return;
          }
          if (!data.confirmed) {
            this.$move("confirm");
            return;
          }
          if (data.registered) {
            this.$move("../");
            return;
          }
          this.siteName = data.siteName;
          this.email = data.email;
          this.userAccountId = data.userAccountId;
          this.memberId = data.memberId;
          Service.prefectures()
              .then(prefectures => {
                this.prefectures = prefectures;
              })
              .catch(e => console.log(e))
        })
        .catch(e => {
          console.log(e);
          this.$move("start");
        })
  },
  methods: {
    login() {
      this.$store.commit("clearRegistrationToken");
      this.$store.dispatch("notify", {
        type: "info",
        message: "会員登録しました。"
      });
      AuthService.login({ loginId: this.email, password: this.form.password });
    },
    addMember() {
      Service.addMember(this.form)
          .then(() => {
            this.$store.commit("clearRegistrationToken");
            this.$store.dispatch("notify", {
              type: "info",
              message: "会員情報を追加しました。",
              duration: 7000
            });
            this.$store.commit("setDefaultMember", this.memberId);
            this.$move("/home");
          });
    },
    register() {
      Service.register(this.form)
          .then(() => this.login())
          .catch(e => {
            this.errors = e.fieldErrors;
            console.log(this.errors);
          });
    }
  }
};
</script>
