<template>
  <default-layout no-auth>
    <form>
      <div class="contents-block has-blue-border">
        <div class="contents-block__body is-narrow">
          <div class="c-block">
            <h1 class="c-heading__lv1"><span class="c-heading__title">認証コードの送信</span></h1>
          </div>
          <div class="c-block u-tac u-mb20">
            <p class="c-text">ログインに使用するメールアドレスを入力してください。<br>入力されたメールアドレス宛に認証コードを送信します。</p>
          </div>
          <alert></alert>
          <div class="c-block">
            <dl class="c-form-block is-narrow">
              <dt>
                <label class="c-form-label" for="">メールアドレス</label>
              </dt>
              <dd>
                <input class="c-input__text" type="email" v-model="email">
              </dd>
            </dl>
          </div>
        </div>
        <div class="contents-block__footer">
          <button class="c-btn is-short u-ma" @click="sendConfirmationCode()" type="button"><span>認証コードを送信</span></button>
        </div>
      </div>
    </form>
  </default-layout>
</template>

<script>
import {Service} from "./_resource.js";
import DefaultLayout from "@/lib/layout/DefaultLayout";

export default {
  components: {DefaultLayout},
  data () {
    return {
      email: "",
      error: null,
    }
  },
  created () {
    Service.getSignUpInfo()
        .then(data => {
          if (data == null || data.used) this.$move("start");
        })
        .catch(() => {
          this.$move("start");
        })
  },
  methods: {
    sendConfirmationCode () {
      Service.sendConfirmationCode(this.email)
          .then(() => {
            this.$move("confirm");
          });
    }
  }
};
</script>
