<template>
  <default-layout no-auth>
    <form>
      <div class="contents-block has-blue-border">
        <div class="contents-block__body is-narrow">
          <div class="c-block">
            <h1 class="c-heading__lv1"><span class="c-heading__title">会員情報の確認</span></h1>
          </div>
          <div class="c-block u-tac">
            <p class="c-text">下記内容でお間違いございませんでしょうか。<br>内容に間違いがある場合は教室までご連絡ください。</p>
          </div>
          <div class="c-block">
            <dl class="c-form-block is-narrow">
              <dt><span class="c-form-label">教室名</span></dt>
              <dd><span class="c-form-text">{{member.siteName}}</span></dd>
              <dt><span class="c-form-label">英語検定ターゲットコースの会員番号</span></dt>
              <dd><span class="c-form-text">{{member.memberCode}}</span></dd>
              <dt><span class="c-form-label">会員氏名（漢字）</span></dt>
              <dd><span class="c-form-text">{{member.familyName}} {{member.givenName}}</span></dd>
              <dt><span class="c-form-label">会員氏名（カナ）</span></dt>
              <dd><span class="c-form-text">{{member.familyNameKana}} {{member.givenNameKana}}</span></dd>
              <dt><span class="c-form-label">生年月日</span></dt>
              <dd><span class="c-form-text">{{member.dateOfBirth | date}}</span></dd>
              <dt><span class="c-form-label">学年</span></dt>
              <dd><span class="c-form-text">{{member.schoolGrade}}</span></dd>
              <dt><span class="c-form-label">性別</span></dt>
              <dd><span class="c-form-text">{{member.sex}}</span></dd>
            </dl>
          </div>
        </div>
        <div class="contents-block__footer">
          <button class="c-btn is-short u-ma" @click="$move('email')" type="button"><span>次へ</span></button>
        </div>
      </div>
    </form>
  </default-layout>
</template>

<script>
import {Service} from "./_resource"

export default {
  data () {
    return {
      member: {
        siteName: "",
        memberCode: "",
        familyName: "",
        givenName: "",
        familyNameKana: "",
        givenNameKana: "",
        schoolGrade: "",
        dateOfBirth: "",
        sex: ""
      }
    }
  },
  created () {
    Service.getMember()
        .then(m => {
          if (m == null) {
            this.$move("start");
            return;
          }
          this.member = m;
        });
  },
  filters: {
    date: date => {
      if (!date) return "";
      let [y, m, d] = date.split("-");
      return y + "年" + m + "月" + d + "日";
    }
  }
}
</script>
