<template>
  <default-layout no-auth>
    <form>
      <div class="contents-block has-blue-border">
        <div class="contents-block__body is-narrow">
          <div class="c-block">
            <h1 class="c-heading__lv1"><span class="c-heading__title">新規会員登録</span></h1>
          </div>
          <div class="c-block u-tac u-mb20">
            <p class="c-text">「英語検定ターゲットコースの会員番号（会員証に記載）」と「生年月日」を<br>入力してください。</p>
          </div>
          <alert></alert>
          <!--
          <div class="c-message is-danger" hidden>
            <p class="u-tac">「英語検定ターゲットコースの会員番号」または「生年月日」が正しくありません。</p>
          </div>
          -->
          <div class="c-block">
            <dl class="c-form-block is-narrow">
              <dt>
                <label class="c-form-label" for="">英語検定ターゲットコースの会員番号</label>
              </dt>
              <dd>
                <input class="c-input__text c-input-member-code" type="text" placeholder="前半の7桁" v-model="memberCode.code1" maxlength="7">
                &nbsp;-&nbsp;
                <input class="c-input__text c-input-member-code" type="text" placeholder="後半の5桁" v-model="memberCode.code2" maxlength="5">
              </dd>
              <dt>
                <label class="c-form-label" for="">生年月日</label>
              </dt>
              <dd>
                <div class="c-input-select c-input-date-of-birth">
                  <select v-model="dateOfBirth.year">
                    <option value="" hidden>年</option>
                    <option v-for="y in years" :key="y" v-bind:value="y">{{y}}年</option>
                  </select>
                </div>
                <div class="c-input-select c-input-date-of-birth">
                  <select v-model="dateOfBirth.month">
                    <option value="" hidden>月</option>
                    <option v-for="m in months" :key="m" v-bind:value="m">{{m}}月</option>
                  </select>
                </div>
                <div class="c-input-select c-input-date-of-birth">
                  <select v-model="dateOfBirth.day">
                    <option value="" hidden>日</option>
                    <option v-for="d in days" :key="d" v-bind:value="d">{{d}}日</option>
                  </select>
                </div>
              </dd>
            </dl>
            <div class="u-mt30 u-tac">
              <p class="c-text">JET委員会の<a class="c-link__text" href="https://www.jet-japan.ne.jp/privacy/" target="_blank">プライバシーポリシー</a>をご確認のうえ、同意して「次へ」進めてください。</p>
              <div class="c-input__group">
                <span class="c-input__item">
                  <input class="c-input__checkbox" id="privacy" type="checkbox" v-model="agreement">
                  <label class="c-input__label" for="privacy">プライバシーポリシーに同意する</label>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="contents-block__footer">
          <button class="c-btn is-short u-ma" :disabled="!filled" @click="memberAuth" type="button"><span>次へ</span></button>
        </div>
      </div>
    </form>
  </default-layout>
</template>

<script>
import {Service} from "./_resource";

export default {
  data () {
    return {
      memberCode: {
        code1: "",
        code2: ""
      },
      dateOfBirth: {
        year: "",
        month: "",
        day: ""
      },
      agreement: false
    }
  },
  computed: {
    years () {
      const year = new Date().getFullYear();
      const years = [];
      for (let y = year - 20; y < year; y++) years.push(y);
      return years;
    },
    months () {
      const months = [];
      for (let m = 1; m <= 12; m++) months.push(m);
      return months;
    },
    days () {
      let end = 31;
      const year = this.dateOfBirth.year;
      const month = this.dateOfBirth.month;
      if (year) {
        if (month) {
          if (month == 2) end = year % 4 == 0 && (year % 100 != 0 || year % 400 == 0) ? 29 : 28;
          if ([4, 6, 9, 11].indexOf(month) != -1) end = 30;
        }
      }
      const days = [];
      for (let d = 1; d <= end; d++) days.push(d);
      return days;
    },
    filled () {
      return this.agreement && this.memberCode.code1 && this.memberCode.code2
          && this.dateOfBirth.year && this.dateOfBirth.month && this.dateOfBirth.day;
    }
  },
  methods: {
    memberAuth () {
      const organiationId = this.$route.params.organizationId || 1;
      const memberCode = this.memberCode.code1 + "-" + this.memberCode.code2;
      const dateOfBirth = this.dateOfBirth.year
          + (this.dateOfBirth.month < 10 ? "-0" : "-") + this.dateOfBirth.month
          + (this.dateOfBirth.day < 10 ? "-0" : "-") + this.dateOfBirth.day;
      Service.memberAuth(organiationId, memberCode, dateOfBirth)
          .then(data => {
            this.$store.commit("setRegistrationToken", data.registrationToken);
            this.$move(this.$route.params.organizationId ? "../member" : "member");
          })
    }
  }
};
</script>
